<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Edit Supplier Category">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">

                <b-row>
                  <b-col md="12" >
                    
                    <b-form-group
                      label="Supplier"
                      class="required"
    
                    >
                      <!-- <b-form-select v-model="form.supplier">
    
                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                        
                        <b-form-select-option :value="supplier._id" v-for="supplier in suppliers" :key="supplier._id">{{supplier.name | capitalize}}</b-form-select-option>
                        
                        
                      </b-form-select> -->

                      <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suppliers"
                          label="name"
                          class="w-100"
                          placeholder="Supplier"
                          
                          v-model="form.supplier"
                          :clearable="false"
                        >
                        <template #selected-option="{ name }">
                            <span v-if="name && name.length < 23">{{ name }}</span>
                            <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                          </template>
                        </v-select>
                        
                    </b-form-group>
                  </b-col>
    
                </b-row>

               

                <b-row class="mb-1">
                  <b-col md="12">
                      <b-form-group
                      label="Category Name"
                      class="required legend_top_space_mobile"
                      >
                      <b-form-input
                          placeholder="3 to 50 characters"
                          v-model="form.name"
                          autocomplete="off"
                      />
                      </b-form-group>
                  </b-col>
                </b-row>
                
               
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select'
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect,
      },
      directives: {
        Ripple,
      },
      data() {
        return {
  
          error_message:null,
          showDismissibleAlert:false,
    
          form :{
            
            supplier:'', 
            name:'',
            id:''         
          },
          
          suppliers:[]
          
        }
      },
      methods : {

        updateSite(){
          this.form.supplier = '';
          // this.form.names = [{name:''}];
          this.getSuppliers();
        },
    
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/update-supplier-category'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Added Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name:'supplier-category' ,params:{supplier_id:this.form.supplier._id}})
                    });
                    
                }
            });
          
        },
  
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'suppliers'},
            text: 'Suppliers Info',
          },{
            to:{name:'supplier-category',params:{supplier_id:this.form.supplier._id}},
            text: 'Categories',
          },{
            to:null,
            text: 'Edit Category',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name:'supplier-category' ,params:{supplier_id:this.form.supplier._id}})
        },
        getSuppliers(){
          return this.$store.dispatch(POST_API, {
            data:{
                  role:this.$store.getters.currentUser.role,
                  
            },
            api: '/api/all-suppliers'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
              } else {
                  this.suppliers = this.$store.getters.getResults.data;
                  return this.suppliers;
              }
          });
        },
        getDetail(){
          return this.$store.dispatch(POST_API, {
            data: {
              id: this.$route.params.id,
            },
            api: '/api/supplier-category-detail'
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
            } else {
              var data = this.$store.getters.getResults.data;
              
              this.form.supplier = data.supplier;
              this.form.name = data.name;
              this.form.id = data._id;
              this.getSuppliers();
              return this.form;
            }
          });
        }   
    
      },
      mounted(){
        
        this.getDetail();
      }
    }
    </script>

    <style lang="scss">
        @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    